header {
  padding: 20px;
}

header h1 {
  margin: 0;
  padding: 0;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
}

.main {
  position: relative;
  width: 100%;
  height: 500px;
  display: block;
  background: radial-gradient(
    15.97% 139.08% at 70.24% 46.11%,
    rgba(240, 246, 255, 0.5) 0%,
    #f0f6ff 100%
  );
  overflow: hidden;
}

.main .container {
  height: 100%;
}

.main-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  text-align: center;
}

.download-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px auto;
}

.download-buttons img {
  height: 42px;
  display: block;
  margin-right: 20px;
}

.main-content h2 {
  font-size: 18px;
  max-width: 400px;
}

.phones {
  display: flex;
  justify-content: space-evenly;
  padding: 0 20px;
}

.phones img {
  max-width: 100%;
  display: block;
}

.phones img.android {
  display: none;
}

.features-list {
  display: flex;
  flex-direction: column;
  margin: 20px 0 0;
  padding: 0;
  list-style: none;
  justify-content: space-evenly;
  align-items: center;
}

.features-list li {
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  flex-direction: row;
  max-width: 800px;
  padding: 0 40px;
  margin-bottom: 20px;
  font-size: 16px;
}

.features-list li:nth-child(2) .features-list__content {
  order: -1;
}

.features-list__content {
  flex: 1;
  padding: 0 20px;
}

.features-list img {
  width: 50%;
  display: block;
}

.features-list .features-list__title-img {
  max-width: 100%;
  width: auto;
}

@media (min-width: 600px) {
  .features-list li {
    font-size: 20px;
  }
}

@media (min-width: 1000px) {
  .main {
    position: relative;
    width: 100%;
    height: 500px;
    display: block;
    background: radial-gradient(
      15.97% 139.08% at 70.24% 46.11%,
      rgba(240, 246, 255, 0.5) 0%,
      #f0f6ff 100%
    );
  }

  .main .container {
    height: 100%;
  }

  .main-content {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
  }

  .main-content h2 {
    font-size: 24px;
    text-align: left;
    max-width: inherit;
  }

  .download-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  .phones {
    position: absolute;
    right: 0;
    width: 50%;
    bottom: 0;
    height: 80%;
    display: flex;
    overflow: hidden;
    justify-content: flex-start;
  }

  .phones img {
    width: auto;
    height: 100%;
  }

  .phones img.android {
    display: block;
  }

  .features-list {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;
  }

  .features-list li {
    flex: 1;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    max-width: 400px;
    padding: 0 40px;
    margin-bottom: 20px;
    font-size: 16px;
  }
  .features-list img {
    width: 100%;
    display: block;
  }

  .features-list li:nth-child(2) .features-list__content {
    order: 0;
  }
}

footer {
  margin-top: 20px;
  display: block;
  background-color: black;
  height: 50px;
}
